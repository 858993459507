import React from "react"
import { Box } from "rebass"

const Write = () => (
  <Box id="write-capacity">
    <h3>Write Capacity</h3>
    <h4>What Is DynamoDB Write Capacity?</h4>
    <p>
      DynamoDB Write Capacity tells us how much data can be written to a DynamoDB
      table. Write Capacity is measured in WCUs.
    </p>
    <h4>What Is DynamoDB WCU?</h4>
    <p>
      WCU or "Write Capacity Unit" represents one write per second, for an item
      up to 1 KB in size.
    </p>
  </Box>
)

export default Write
