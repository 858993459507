import React from 'react';
import { Flex } from 'rebass';

const DynamoDBCapacityFilter = () => (
    <Flex justifyContent={['space-evenly', 'space-between']} flexWrap='wrap'>
        <a href='#read-capacity' style={{textDecoration: 'none'}}>
            <p>Read</p>
        </a> / 
        <a href='#write-capacity' style={{textDecoration: 'none'}}>
        <p>Write &nbsp;</p> 
        </a> / 

        <a href='#provisioned-capacity' style={{textDecoration: 'none'}}>
        <p>Provisioned</p>
        </a> /

        <a href='#reserved-capacity' style={{textDecoration: 'none'}}>
        <p>Reserved</p>
        </a> /

        <a href='#adaptive-capacity' style={{textDecoration: 'none'}}>
        <p>Adaptive</p>
        </a> /

        <a href='#burst-capacity' style={{textDecoration: 'none'}}>
        <p>Burst</p>
        </a> /

        <a href='#ondemand-capacity' style={{textDecoration: 'none'}}>
        <p>On-Demand &nbsp;</p>
        </a>
    </Flex>      
);

export default DynamoDBCapacityFilter;