import React from 'react';
import { Box } from 'rebass'
import ReadCapacity from './Read'
import WriteCapacity from './Write'
import ProvisionedCapacity from './Provisioned'
import ReservedCapacity from './Reserved'
import AdaptiveCapacity from './Adaptive'
import BurstCapacity from './Burst'
import OnDemandCapacity from './OnDemand'
import Filter from './Filter'

const Content = () => (
       <Box mt={[32, 62]}>
            <Filter />
            <ReadCapacity />
            <WriteCapacity />
            <ProvisionedCapacity />
            <ReservedCapacity />
            <AdaptiveCapacity />
            <BurstCapacity />
            <OnDemandCapacity />
       </Box>   
);

export default Content;