import React from "react"
import { Box } from "rebass"

const Provisioned = props => (
  <Box id="provisioned-capacity">
    <h3>DynamoDB Provisioned Capacity</h3>
    <p>
      DynamoDB Provisioned Capacity is the amount of read and write operations
      that a DynamoDB table can support. DynamoDB uses this information to
      reserve sufficient system resources internally to meet your I/O
      requirements.
    </p>
  </Box>
)

export default Provisioned
