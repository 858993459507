import React from "react"
import { FooterWithCTA, SEO, Layout, Container, Hero } from "@components"
import Content from "@components/pages/capacity-modes/Content/index.js"
import FAQ from "@components/pages/capacity-modes/FAQ.js"

const CapacityCalculator = () => {
  return (
    <>
      <Layout>
        <SEO
          title="Understand All DynamoDB Capacity Modes: Read, Write, Provisioned &amp; More."
          description="Learn about all DynamoDB capacity modes: Read, Write, Provisioned, Reserved, Adaptive, Burst & On-Demand."
          canonical="https://dynobase.dev/dynamodb-capacity-modes/"
        />
        <Hero title="Understand All DynamoDB Capacity Modes" />
        <Container>
          <Content />
          <FAQ />
        </Container>
        <FooterWithCTA />
      </Layout>
    </>
  )
}

export default CapacityCalculator
