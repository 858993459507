import React from "react"
import { Box } from "rebass"
import { InternalLink } from '@fragments'

const OnDemand = props => (
  <Box id="ondemand-capacity">
    <h3>DynamoDB On-Demand Capacity</h3>
    <p>
      This is the truly Serverless mode of DynamoDB. In on-demand capacity mode,
      you're billed for the exact amount of data reads and writes. You don't
      need to provision any upfront number, it's pay-what-you-use model. If you
      have a rough number of read and write operations your app will be doing, you
      can use <InternalLink to="/dynamodb-pricing-calculator/">our costs calculator</InternalLink>{" "}
      to get an estimate.
    </p>
  </Box>
)

export default OnDemand
