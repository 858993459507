import React from "react"
import { Box } from "rebass"

const Burst = props => (
  <Box id="burst-capacity">
    <h3>DynamoDB Burst Capacity</h3>
    <p>
      To prevent from an excessive amount of throttling, DynamoDB provides some
      flexibility around consuming read and write capacity. Whenever DynamoDB
      clients are not fully using provisioned throughput, DynamoDB retains up to
      5 minutes of unused read and write capacity. During an occasional spike of
      activity, these extra capacity units accumulated in that period will be
      consumed to satisfy the I/O requirements.
    </p>
  </Box>
)

export default Burst
