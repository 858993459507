import React from "react"
import { Box } from "rebass"

const Read = props => (
  <Box id="read-capacity">
    <h3>Read Capacity</h3>
    <h4>What Is DynamoDB Read Capacity?</h4>
    <p>
      Read Capacity tells us how much data can be read from a DynamoDB
      table. Read Capacity is measured in RCUs.
    </p>
    <h4>What Is DynamoDB RCU?</h4>
    <p>
      RCU or "Read capacity unit" represents one strongly consistent read per
      second, or two eventually consistent reads per second, for an item up to 4
      KB in size.
    </p>
  </Box>
)

export default Read
