import React from "react"
import { Box } from "rebass"

const Adaptive = props => (
  <Box id="adaptive-capacity">
    <h3>DynamoDB Adaptive Capacity</h3>
    <p>
      Sometimes your read and writes operations are not evenly distributed among
      keys and partitions. This kind of imbalanced workload can lead to hot
      partitions and in consequence -{" "}
      <a href="#throttling">throttling</a>. Adaptive
      Capacity aims to solve this problem bt allowing to continue reading and
      writing form these partitions without rejections. Adaptive capacity is
      enabled automatically and instantly for partitions that receive more
      traffic.
    </p>
  </Box>
)

export default Adaptive
