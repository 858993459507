import React from "react"
import { Box } from "rebass"

const Reserved = props => (
  <Box id="reserved-capacity">
    <h3>DynamoDB Reserved Capacity</h3>
    <p>
      Reserved Capacity is a cost-saving feature that allows you to obtain
      discounts on your provisioned DynamoDB tables throughput capacity in
      exchange for a commitment to a certain usage level with 1-year or 3-year
      terms and a one-time up-front payment. Costs savings can be as high as 76%,
      but that depends on the selected terms and whether you're going to pay partially 
      or fully upfront.
    </p>
  </Box>
)

export default Reserved
