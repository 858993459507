import React from "react"
import { Box } from "rebass"
import { InternalLink, ExternalLink } from "@fragments"
import { freeTools } from "@constants/urls.js"
import FAQJsonLD from "@components/FAQJsonLD"

const FAQ = props => (
  <Box mt={[32, 62]}>
    <h2>Frequently Asked Questions</h2>

    <FAQJsonLD
      questions={[
        {
          question: "What Is DynamoDB Read Capacity?",
          answer:
            "Read Capacity tells us how much data can be read from a DynamoDB table. Read Capacity is measured in RCUs.",
        },
        {
          question: "What Is DynamoDB RCU?",
          answer: `RCU or "Read capacity unit" represents one strongly consistent read per second, or two eventually consistent reads per second, for an item up to 4 KB in size.`,
        },
        {
          question: "What Is DynamoDB Write Capacity?",
          answer:
            "DynamoDB Write Capacity tells us how much data can be written to a DynamoDB table. Write Capacity is measured in WCUs.",
        },
        {
          question: "What Is DynamoDB WCU?",
          answer: `WCU or "Write Capacity Unit" represents one write per second, for an item up to 1 KB in size.`,
        },
        {
          question: "How do you select capacity mode in DynamoDB?",
          answer:
            "It's up to you. There are two selectable capacity modes in DynamoDB: Provisioned and On-Demand. While On-demand is perfect for unpredictable workloads with sudden spikes of traffic, the provisioned mode is cheaper and better suited for workloads with predictable traffic.",
        },
        {
          question: "How do you scale DynamoDB?",
          answer:
            "You don't! At least in not in the classical sense. AWS DynamoDB is fully managed service. It means that AWS engineers take care of provisioning and running machines where DynamoDB runs. Your responsibility is reduced to table data administration and setting capacity mode, plus the amount of WCU and RCU.",
        },
        {
          question: "What is throttling in DynamoDB?",
          answer:
            "Throttled is a synonym for <b>rejected</b>. There are two causes for that: First,  <b>Not enough capacity</b> - you've exhausted write capacity of a table or in other words, you're trying to write much more data than the table was provisioned for. The solution for this is simple: provision more Write Capacity Units. Second, <b>Hot partitions</b> - your writes are getting rejected because you're requesting data from one of the partitions much more frequently than from the rest of the table or other partition. In this case, the solution might be not that simple. <br /> First, you'll need to identify which keys are affected and then try to distribute reads and writes as evenly as possible across your table. <br /> You should also think about caching using DAX or in-memory store.",
        },
      ]}
    />

    <h3>How do you select capacity mode in DynamoDB?</h3>
    <p>
      It's up to you. There are two selectable capacity modes in DynamoDB:
      Provisioned and On-Demand. While On-demand is perfect for unpredictable
      workloads with sudden spikes of traffic, the provisioned mode is cheaper
      and better suited for workloads with predictable traffic.
    </p>

    <Box width={1} p={4} backgroundColor={"#f8f8f8"} my={3}>
      Compare modes and simulate costs using our{" "}
      <InternalLink to={freeTools.pricingCalculator}>
        DynamoDB Pricing Calculator
      </InternalLink>
    </Box>

    <h3>
      What happens if the application performs more reads or writes than your
      provisioned capacity?
    </h3>
    <p>Burst capacity kicks in.</p>

    <h3>How do you scale DynamoDB?</h3>
    <p>
      You don't! At least in not in the classical sense. AWS DynamoDB is fully
      managed service. It means that AWS engineers take care of provisioning and
      running machines where DynamoDB runs. Your responsibility is reduced to
      table administration.
    </p>
    <p>
      DynamoDB scaling applies only to some extent when you're using Provisioned
      capacity mode. It is possible that the provisioned amount of read/write
      capacity units might be insufficient sometimes, and this is the moment
      when you should consider updating your table with a bigger number of
      provisioned read and write units. If you're creating DynamoDB table using
      AWS Console, the{" "}
      <InternalLink to="/dynamodb-on-demand-vs-provisioned-scaling/">
        autoscaling
      </InternalLink>{" "}
      option there is enabled by default. For more information, head to{" "}
      <ExternalLink to="https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/AutoScaling.html">
        official AWS guide.
      </ExternalLink>
    </p>

    <h3 id="throttling">What is throttling in DynamoDB?</h3>
    <p>
      Throttled is a synonym for <b>rejected</b>. There are two causes for that:
    </p>
    <ul>
      <li>
        <b>Not enough capacity</b> - you've exhausted write capacity of a table
        or in other words, you're trying to write much more data than the table
        was provisioned for. The solution for this is simple: provision more
        Write Capacity Units.
      </li>
      <li>
        <b>Hot partitions</b> - your writes are getting rejected because you're
        requesting data from one of the partitions much more frequently than
        from the rest of the table or other partition. In this case, the
        solution might be not that simple. <br />
        First, you'll need to identify which keys are affected and then try to
        distribute reads and writes as evenly as possible across your table.
        <br /> You should also think about caching using DAX or in-memory store.
      </li>
    </ul>

    <h3>How long does it take to update DynamoDB table capacity?</h3>
    <p>It's instant.</p>

    <h3 id="provisioned-vs-on-demand">
      How does DynamoDB provisioned capacity differ from on-demand capacity?
    </h3>
    <p>There are two fundamental differences:</p>
    <ul>
      <li>
        Provisioned Capacity is "provisioned". This means you as a user have to
        define an exact number of read and write capacity units available per
        table. In on-demand capacity mode, you're not provisioning or setting
        any number of units.
      </li>
      <li>
        Tables with On-demand capacity mode are billed per "Read/Write Capacity
        Units" (RCU/WCU) while tables with provisioned capacity are billed per
        "Read/Write Request Units" (RRU/WRU).
        <br />
        <br />
        One RCU can perform one standard (up to 4 KB) read request per second.
        One RRU can perform one standard (up to 4 KB) read request (not per
        second!)
        <br />
        One WCU can perform one standard (up to 1 KB) write request per second.
        One WRU can perform one standard (up to 1 KB) write request (not per
        second!)
      </li>
    </ul>

    <h3>What in DynamoDB does not consume capacity units?</h3>
    <p>
      There are few things in DynamoDB that don't consume any capacity units,
      these are:
    </p>
    <ul>
      <li>
        Creating snapshot backup of the table and restoring it (billed per
        GB/month)
      </li>
      <li>
        Having Continious backups for point in time recovery enabled (billed per
        GB/month)
      </li>
      <li>Running DynamoDB Accelerator aka DAX (billed per Node-hour)</li>
      <li>
        Streaming changes using DynamoDB Streams (billed on stream read request
        units, separate from table RCU/RRU)
      </li>
      <li>Transferring the data to another region (billed per GB)</li>
    </ul>
  </Box>
)

export default FAQ
